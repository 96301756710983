<template>
  <v-list-item>
    <v-list-item-content>
      <v-list-item-title>
        {{ puck.name }}
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
    name: 'PuckListItem',
    props: {
      puck: {
        type: Object, 
        default: () => {}
      }
    }
}
</script>